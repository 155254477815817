import * as $ from "jquery";
import * as Popper from "popper.js";
import "bootstrap";
import svg4everybody from "svg4everybody";

window.$ = $;
window.jQuery = $;
window.Popper = Popper;

svg4everybody();
